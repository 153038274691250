import {
  AvailableSalesItemForBookingItem,
  AvailableSalesItemForNewCarInBooking,
  BookingItemOnRoute,
  CarTireMeasurements,
  EstimatedRoutesPayment,
  PaginatedInput,
  PaginatedResponse,
  RegisteredCapacityContribution,
  RouteForServiceWorker,
  RouteItemCustomerOverview,
  RouteItemForServiceWorker,
  RouteSettlementPayment,
  ServiceWorker,
  ServiceWorkerFeedback,
  ServiceWorkerInput,
  ServiceWorkerList,
  ServiceWorkerType,
  UnableToCompleteReason,
  WorkItem
} from '../types';
import { FetchMethods, ResponseType, apiVersion } from './commonResourceTypes';
import { CommonAxiosResType, getNoddiUrl } from './resources';

export const WorkerURLKeys = {
  getPaginatedServiceWorkers: 'getPaginatedServiceWorkers',
  getServiceWorkers: 'getServiceWorkers',
  getServiceWorker: 'getServiceWorker',
  postServiceWorker: 'postServiceWorker',
  patchServiceWorker: 'patchServiceWorker',
  getServiceWorkerTypes: 'getServiceWorkerTypes',
  postDelayedMessage: 'postDelayedMessage',
  postMarkRouteItemComplete: 'postMarkRouteItemComplete',
  getUnableToCompleteReasons: 'getUnableToCompleteReasons',
  getCapacityContributionsForServiceWorker: 'getCapacityContributionsForServiceWorker',
  postAddExtraSalesItemsToBookingItem: 'postAddExtraSalesItemsToBookingItem',
  postMarkRouteItemStarted: 'postMarkRouteItemStarted',
  getRouteSettlementsForWorker: 'getRouteSettlementsForWorker',
  postStartRouteForServiceWorker: 'postStartRouteForServiceWorker',
  postConfirmRouteForServiceWorker: 'postConfirmRouteForServiceWorker',
  getRoutesForServiceWorker: 'getRoutesForServiceWorker',
  getRouteItemsForServiceWorker: 'getRouteItemsForServiceWorker',
  getEstimatedRoutePayment: 'getEstimatedRoutePayment',
  getDelayForRoute: 'getDelayForRoute',
  getBookingItemsForRoute: 'getBookingItemsForRoute',
  getRouteItemCustomerOverview: 'getRouteItemCustomerOverview',
  postMarkBookingItemsAsComplete: 'postMarkBookingItemsAsComplete',
  postMarkBookingAsComplete: 'postMarkBookingAsComplete',
  postAddCarTireMeasurements: 'postAddCarTireMeasurements',
  postNextCustomerArrivalSMS: 'postNextCustomerArrivalSMS',
  getAvailableSalesItemsForBookingItem: 'getAvailableSalesItemsForBookingItem',
  postTranslateText: 'postTranslateText',
  postUnableToCompleteCarOnBookingItem: 'postUnableToCompleteCarOnBookingItem',
  getSalesItemForNewCarInBooking: 'getSalesItemForNewCarInBooking',
  getCapacityContributionsForDate: 'getCapacityContributionsForDate',
  getCapacityContributionDates: 'getCapacityContributionDates',
  getServiceWorkerFeedbacks: 'getServiceWorkerFeedbacks'
} as const;

export type WorkerURLKeysType = keyof typeof WorkerURLKeys;

type InputDataOrVoid<Type extends WorkerURLKeysType> = InputData<Type> extends void ? void : InputData<Type>;

type InputData<urlKey extends WorkerURLKeysType> = WorkerServerRequests[urlKey]['input'];

export const getWorkerUrls: {
  [key in WorkerURLKeysType]: {
    getUrl: (input: InputDataOrVoid<key>) => string;
    getBody?: (input: InputDataOrVoid<key>) => Partial<InputData<key>>;
    method: FetchMethods;
    responseType?: ResponseType;
    handleRes?: (res: CommonAxiosResType<key>) => Promise<unknown>;
    getHeaders?: (input: InputDataOrVoid<key>) => Record<string, string>;
    useSuperUserToken?: boolean;
    skipConvertingToCamelCase?: boolean;
  };
} = {
  getPaginatedServiceWorkers: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getServiceWorkers: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getServiceWorker: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${id}`
      }),
    method: FetchMethods.get
  },
  postServiceWorker: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/`
      }),
    getBody: (ServiceWorkerInput) => ServiceWorkerInput,
    method: FetchMethods.post
  },
  patchServiceWorker: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${id}`
      }),
    getBody: (ServiceWorkerInput) => ServiceWorkerInput,
    method: FetchMethods.patch
  },
  getServiceWorkerTypes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/types/`
      }),
    method: FetchMethods.get
  },
  postDelayedMessage: {
    getUrl({ routeItemId }) {
      return getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/send-delay-sms/`
      });
    },
    getBody: ({ numMinutes }) => ({ numMinutes }),
    method: FetchMethods.post
  },
  postMarkRouteItemComplete: {
    getUrl({ bookingId }) {
      return getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/mark-as-complete/`
      });
    },
    getBody: ({ commentsWorker, notifyCustomer }) => ({ commentsWorker, notifyCustomer }),
    method: FetchMethods.post
  },
  getCapacityContributionsForServiceWorker: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/for-service-worker/`
      }),
    method: FetchMethods.get
  },
  getUnableToCompleteReasons: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/unable-to-complete-reasons/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postAddExtraSalesItemsToBookingItem: {
    getUrl({ id }) {
      return getNoddiUrl({
        path: `${apiVersion}booking-items/${id}/add-extra-sales-items/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postMarkRouteItemStarted: {
    getUrl({ routeItemId }) {
      return getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/mark-as-started/`
      });
    },
    method: FetchMethods.post
  },
  getRouteSettlementsForWorker: {
    getUrl: ({ userId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}route-settlements/for-worker/${userId}`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postStartRouteForServiceWorker: {
    getUrl({ routeId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/start-route-for-service-worker/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postConfirmRouteForServiceWorker: {
    getUrl({ routeId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/confirm-route-for-service-worker/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getRoutesForServiceWorker: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}routes/for-service-worker/`
      }),
    method: FetchMethods.get
  },
  getDelayForRoute: {
    getUrl: ({ routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/delay/`
      }),
    method: FetchMethods.get
  },
  getEstimatedRoutePayment: {
    getUrl: ({ routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/estimated-payment/`
      }),
    method: FetchMethods.get
  },
  getBookingItemsForRoute: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/booking-items/`
      }),
    method: FetchMethods.get
  },
  getRouteItemsForServiceWorker: {
    getUrl: ({ routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items-for-service-worker/`
      }),
    method: FetchMethods.get
  },
  getRouteItemCustomerOverview: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/customer-overview/`
      }),
    method: FetchMethods.get
  },
  postMarkBookingItemsAsComplete: {
    getUrl: ({ bookingItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/mark-as-complete/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postMarkBookingAsComplete: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/mark-as-complete/`
      }),
    method: FetchMethods.post
  },
  postAddCarTireMeasurements: {
    getUrl: ({ carId }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${carId}/add-measurements/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postNextCustomerArrivalSMS: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/send-next-arrival-sms/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getAvailableSalesItemsForBookingItem: {
    getUrl: ({ bookingItemId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/available-sales-items/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postTranslateText: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}core/translate-text/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postUnableToCompleteCarOnBookingItem: {
    getUrl: ({ routeItemId, routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/mark-booking-item-as-unable-to-complete/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getSalesItemForNewCarInBooking: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/available-for-new-car-on-booking/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCapacityContributionsForDate: {
    getUrl: ({ serviceWorkerId, ...rest }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${serviceWorkerId}/capacity-contributions-v2/`,
        queryParams: rest
      }),
    method: FetchMethods.get
  },
  getCapacityContributionDates: {
    getUrl: ({ serviceWorkerId, ...rest }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${serviceWorkerId}/capacity-contribution-dates/`,
        queryParams: rest
      }),
    method: FetchMethods.get
  },
  getServiceWorkerFeedbacks: {
    getUrl: ({ serviceWorkerId, ...rest }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${serviceWorkerId}/feedbacks/`,
        queryParams: rest
      }),
    method: FetchMethods.get
  }
} as const;

export interface WorkerServerRequests {
  getPaginatedServiceWorkers: {
    input: PaginatedInput;
    output: PaginatedResponse<ServiceWorkerList[]>;
  };
  getServiceWorkers: {
    input: { search?: string };
    output: ServiceWorkerList[];
  };
  getServiceWorker: {
    input: { id: number | string | undefined | null };
    output: ServiceWorker;
  };
  postServiceWorker: {
    input: ServiceWorkerInput;
    output: ServiceWorker;
  };
  patchServiceWorker: {
    input: ServiceWorkerInput & { id: number | string | undefined | null };
    output: ServiceWorker;
  };
  getServiceWorkerTypes: {
    input: undefined;
    output: ServiceWorkerType[];
  };
  postDelayedMessage: {
    input: {
      routeItemId: number;
      numMinutes: number | undefined;
    };
    output: undefined;
  };
  postMarkRouteItemComplete: {
    input: {
      bookingId: number;
      commentsWorker?: string;
      notifyCustomer?: boolean;
    };
    output: undefined;
  };
  getCapacityContributionsForServiceWorker: {
    input: undefined;
    output: WorkItem[];
  };
  getUnableToCompleteReasons: {
    input: {
      isForBooking?: boolean;
      salesItemIds?: number[];
    };
    output: UnableToCompleteReason[];
  };
  postAddExtraSalesItemsToBookingItem: {
    input: {
      id: number;
      salesItemIds: number[];
    };
    output: undefined;
  };
  postMarkRouteItemStarted: {
    input: {
      routeItemId: number;
    };
    output: undefined;
  };
  getRouteSettlementsForWorker: {
    input: PaginatedInput & { userId: string; type: number };
    output: PaginatedResponse<RouteSettlementPayment[]>;
  };
  postStartRouteForServiceWorker: {
    input: {
      routeId: number;
    };
    output: undefined;
  };
  postConfirmRouteForServiceWorker: {
    input: {
      routeId: number;
    };
    output: undefined;
  };
  getRoutesForServiceWorker: {
    input: undefined;
    output: RouteForServiceWorker[];
  };
  getRouteItemsForServiceWorker: {
    input: { routeId: number };
    output: RouteItemForServiceWorker[];
  };
  getEstimatedRoutePayment: {
    input: { routeId: number };
    output: EstimatedRoutesPayment;
  };
  getDelayForRoute: {
    input: { routeId: number };
    output: { delayInSeconds: number };
  };
  getBookingItemsForRoute: {
    input: { routeItemId: number };
    output: BookingItemOnRoute[];
  };
  getRouteItemCustomerOverview: {
    input: { routeItemId: number };
    output: RouteItemCustomerOverview;
  };
  postMarkBookingItemsAsComplete: {
    input: { bookingItemId: number; skipCarWheelMeasurementsReason?: string | null };
    output: undefined;
  };
  postMarkBookingAsComplete: {
    input: { bookingId: number };
    output: undefined;
  };
  postAddCarTireMeasurements: {
    input: { carId: number; bookingItemId?: number; measurements: CarTireMeasurements };
    output: undefined;
  };
  postNextCustomerArrivalSMS: {
    input: { routeItemId: number; numMinutes: number };
    output: undefined;
  };
  getAvailableSalesItemsForBookingItem: {
    input: { bookingItemId: number; type?: 'extra' | null };
    output: AvailableSalesItemForBookingItem[];
  };
  postTranslateText: {
    input: { text: string; targetLanguage: string; sourceLanguage?: string };
    output: { data: { translatedText: string } };
  };
  postUnableToCompleteCarOnBookingItem: {
    input: {
      routeItemId: number;
      routeId: number;
      bookingItemId: number;
      commentsInternal: string;
      commentsPublic: string;
      salesItems: { salesItemId: number; unableToCompleteReasonId: number }[];
    };
    output: { data: { translatedText: string } };
  };
  getSalesItemForNewCarInBooking: {
    input: { bookingId: number; carLicensePlateNumber: string; carCountryCode: string };
    output: AvailableSalesItemForNewCarInBooking[];
  };
  getCapacityContributionsForDate: {
    input: { serviceWorkerId: number; fromDate: string; toDate: string };
    output: RegisteredCapacityContribution[];
  };
  getCapacityContributionDates: {
    input: { serviceWorkerId: number; fromDate: string };
    output: { date: string; id: number }[];
  };
  getServiceWorkerFeedbacks: {
    input: {
      serviceWorkerId: number;
      bookingFromDate?: string;
      bookingToDate?: string;
      createdFromDate?: string;
      createdToDate?: string;
    };
    output: ServiceWorkerFeedback;
  };
}
