import { SxProps } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NoddiButton, useIsMobile } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

export interface LinkProps {
  title: string;
  path?: string;
}

export interface BreadcrumbProps {
  links: LinkProps[];
  sx?: SxProps;
}

export function NoddiBreadcrumbs({ links, sx }: BreadcrumbProps) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <div className='mr-14'>
      <Breadcrumbs maxItems={isMobile ? 3 : 4} separator='/' sx={sx}>
        {links.map(({ title, path }) =>
          path ? (
            <NoddiButton className='p-0' variant='link' onClick={() => navigate(path)} key={path}>
              {title}
            </NoddiButton>
          ) : (
            <span key={title}>{title}</span>
          )
        )}
      </Breadcrumbs>
    </div>
  );
}
